import Akili from 'akili';
import request from 'akili/src/services/request';

export default class Code extends Akili.Component {
  static define() {
    Akili.component('code', this);
  }

  constructor(...args) {
    super(...args);

    this.prevent();
  }

  compiled() {
    this.attr('url', url => this.getTemplate(url).catch((err) => console.error(err)), { callOnStart: false });
    this.attr('value', this.setValue, { callOnStart: false });
    
    if(this.attrs.hasOwnProperty('url')) {
      return this.getTemplate(this.attrs.url);
    }
    else if(this.attrs.hasOwnProperty('value')) {
      this.setValue(this.attrs.value);
    }
    else {      
      let html = this.el.innerHTML;
      html = html.trim();
      html = html.replace(/^<!--|-->$/g, '');
      this.setValue(html);
    }
  }

  getTemplate(value) {
    return request.get(value).then((res) => {
      this.setValue(res.data);
    });
  }
  
  setValue(val) {    
    this.el.innerHTML = this.format(val);
    this.setHighlight();
  }

  setHighlight() {
    window.hljs.highlightBlock(this.el);
    this.el.innerHTML = this.el.innerHTML.replace(/\${(((?!\${).)*)}/g, '<span class="hljs-interpolation">$&</span>');
  }

  format(str) {
    str = str.trim();
    str = str.replace(/<([^<]+)>/g, '&lt;$1&gt;');
    
    if(!this.el.classList.contains('html')) {
      return str;
    }

    str = str.replace(/["']+\/examples\/([\.\w\d-]+)["']+/ig, '"$1"');
    str = str.replace(/((href|src)=["']+)\//ig, '$1https://akilijs.com/');
    return str;
  }
}
