import fields from './docs-fields-data';

const fn = {};

fn['Akili.compile'] = {
  name: 'Akili.compile',
  description: `
    Compile function returns promise because some components might be compiled asynchronously.
    For example, those which have template in the separate file.
    But the compilation itself occurs synchronously in the hierarchy from top to bottom, from left to right.
  `,
  returns: 'Promise',
  args: [
    {
      ...fields.element,
      description: 'root element for the compilation',
      required: true
    },
    {
      name: 'options',
      type: ['Object'],
      defaultValue: '{recompile: false}',
      description: `
        compilation options. 
        If you pass {recompile: true} all previously compiled elements will be recompiled.
      `
    }
  ]
};

fn['Component.prototype.matches'] = {
  name: 'Component.prototype.matches',
  description: `
    This function is necessary to check if the component matches the specified selector.
  `,
  returns: 'boolean',
  args: [
    {
      ...fields.selector,
      required: true
    }
  ]
};

fn['Component.prototype.parent'] = {
  name: 'Component.prototype.parent',
  description: `
    This function returns the closest parent component.
  `,
  returns: 'Component',
  args: [
    fields.selector,
    fields.levels
  ]
};

fn['Component.prototype.parents'] = {
  ...fn['Component.prototype.parent'],
  name: 'Component.prototype.parents',
  description: `
    This function returns the list of parent components.
  `,
  returns: 'Component[]'
};

fn['Component.prototype.child'] = {
  ...fn['Component.prototype.parent'],
  name: 'Component.prototype.child',
  description: `
    This function returns the closest child component.
  `,
  returns: 'Component'
};

fn['Component.prototype.children'] = {
  ...fn['Component.prototype.child'],
  name: 'Component.prototype.children',
  description: `
    This function returns the list of child components.
  `,
  returns: 'Component[]'
};

fn['Component.prototype.prev'] = {
  name: 'Component.prototype.prev',
  description: `
    This function returns the closest left component.
  `,
  returns: 'Component',
  args: [
    fields.selector
  ]
};

fn['Component.prototype.before'] = {
  name: 'Component.prototype.before',
  description: `
    This function returns the list of components left.
  `,
  returns: 'Component[]',
  args: [
    fields.selector
  ]
};

fn['Component.prototype.next'] = {
  name: 'Component.prototype.next',
  description: `
    This function returns the closest right component.
  `,
  returns: 'Component',
  args: [
    fields.selector
  ]
};

fn['Component.prototype.after'] = {
  name: 'Component.prototype.after',
  description: `
    This function returns the list of components right.
  `,
  returns: 'Component[]',
  args: [
    fields.selector
  ]
};

fn['Component.prototype.appendTo'] = {
  name: 'Component.prototype.appendTo',
  description: `
    This function changes the element parent of the component.
  `,
  args: [
    {
      ...fields.element,
      description: `Parent element`,
      required: true
    }
  ]
};

fn['Component.prototype.empty'] = {
  name: 'Component.prototype.empty',
  description: `
    This function removes the content of the component.
  `
};

fn['Component.prototype.remove'] = {
  name: 'Component.prototype.remove',
  description: `
    This function removes the component.
  `
};

fn['Component.prototype.store'] = {
  name: 'Component.prototype.store',
  description: `
    This function creates the link with the store.
  `,
  args: [
    {
      name: 'name',
      type: ['string'],
      required: true,
      description: `link name`
    },
    {
      name: 'handler',
      type: ['string', 'string[]', 'function'],
      required: true,
      description: `scope property or a function to link`
    },
    {
      name: 'options',
      type: ['Object'],
      description: `options, for example, { callOnStart: false }`
    }
  ]
};

fn['Component.prototype.unstore'] = {
  name: 'Component.prototype.unstore',
  description: `
    This function removes the link with the store.
  `,
  args: [
    {
      name: 'name',
      type: ['string'],
      required: true,
      description: `link name`
    },
    {
      name: 'handler',
      type: ['string', 'string[]', 'function'],
      required: true,
      description: `scope property or a function to unlink`
    }
  ]
};

fn['Component.prototype.attr'] = {
  name: 'Component.prototype.attr',
  description: `
    This function creates the link with the attribute.
  `,
  args: [
    {
      name: 'name',
      type: ['string'],
      required: true,
      description: `attribute name`
    },
    {
      name: 'handler',
      type: ['string', 'string[]', 'function'],
      required: true,
      description: `scope property or a function to link`
    },
    {
      name: 'options',
      type: ['Object'],
      description: `options, for example, { callOnStart: false }`
    }
  ]
};

fn['Component.prototype.unattr'] = {
  name: 'Component.prototype.unattr',
  description: `
    This function removes the link with the attrtibute.
  `,
  args: [
    {
      name: 'name',
      type: ['string'],
      required: true,
      description: `attribute name`
    },
    {
      name: 'handler',
      type: ['string', 'string[]', 'function'],
      required: true,
      description: `scope property or a function to unlink`
    }
  ]
};

fn['Component.prototype.constructor'] = {
  name: 'Component.prototype.constructor',
  args: [
    {
      ...fields.element,
      description: `html element controlled by the component`,
      required: true
    }, {
      ...fields.scope,
      description: `object to synchronize with the component template (content)`,
      required: true
    }
  ]
};

fn['Component.prototype.created'] = {
  name: 'Component.prototype.created'
};

fn['Component.prototype.compiled'] = {
  name: 'Component.prototype.compiled',
  returns: 'Promise'
};

fn['Component.prototype.recompiled'] = {
  name: 'Component.prototype.recompiled'
};

fn['Component.prototype.resolved'] = {
  name: 'Component.prototype.resolved',
  returns: 'Promise'
};

fn['Component.prototype.removed'] = {
  name: 'Component.prototype.removed'
};

fn['router.add'] = {
  name: 'router.add',
  description: `
    Add a new state to the router. 
    You can pass an object as the first argument with "state" and "pattern" as properties 
  `,
  args: [
    fields.state,
    {
      name: 'pattern',
      type: ['string'],
      description: `
        url pattern to link with the state
      `,
      required: true
    },
    {
      name: 'options',
      type: ['Object'],
      description: `
        other state options
      `,
      required: true
    }
  ]
};

fn['router.state'] = {
  name: 'router.state',
  description: `
    Go to the necessary route by state.
  `,
  args: [
    fields.state,
    fields.stateParams,
    fields.stateQuery,
    fields.stateHash,
    fields.stateOptions
  ]
};

fn['router.location'] = {
  name: 'router.location',
  description: `
    Go to the necessary route by url.
  `,
  args: [
    fields.url,
    fields.stateOptions
  ]
};

fn['router.init'] = {
  name: 'router.init',
  description: `
    Initialize the router.
  `,
  args: [
    {
      name: 'defaultUrl',
      type: ['string'],
      description: `
        default url
      `
    },
    {
      name: 'hashHistoryMode',
      type: ['string'],
      defaultValue: 'true',
      description: `
        use the hash history mode or window.history
      `
    }
  ]
};

fn['router.reload'] = {
  name: 'router.reload',
  description: `
    Reload the current state.
  `,
  args: [
    fields.stateParams,
    fields.stateQuery,
    fields.stateHash,
    fields.stateOptions
  ],
  returns: 'Promise'
};

fn['router.back'] = {
  name: 'router.back',
  description: `
    Go back on the history.
  `
};

fn['router.forward'] = {
  name: 'router.forward',
  description: `
    Go forward on the history.
  `
};

fn['router.go'] = {
  name: 'router.go',
  description: `
    Go to the position.
  `,
  args: [
    {
      name: 'position',
      type: ['integer'],
      description: `
        history position (window.history.go)
      `,
      required: true
    }
  ]
};

fn['Request.prototype.constructor'] = {
  name: 'Request.prototype.constructor',
  description: `
    Create a new request instance.
  `,
  args: [
    {
      name: 'baseUrl',
      type: ['string'],
      description: `
        initial part of the URL for any request
      `,
      defaultValue: '""'
    },
    {
      name: 'defaultOptions',
      type: ['object'],
      description: `
        default options for all requests
      `
    }
  ]
};

fn['Request.prototype.query'] = {
  name: 'Request.prototype.query',
  description: `
    The base method to make request.
  `,
  returns: 'Promise',
  args: [
    fields.url,
    fields.requestOptions
  ]
};

fn['Request.prototype.get'] = {
  name: 'Request.prototype.get',
  description: `
    GET request.
  `,
  returns: 'Promise',
  args: [
    fields.url,
    fields.requestOptions
  ]
};

fn['Request.prototype.post'] = {
  name: 'Request.prototype.post',
  description: `
    POST request.
  `,
  returns: 'Promise',
  args: [
    fields.url,
    fields.requestOptions
  ]
};

fn['Request.prototype.put'] = {
  name: 'Request.prototype.put',
  description: `
    PUT request.
  `,
  returns: 'Promise',
  args: [
    fields.url,
    fields.requestOptions
  ]
};

fn['Request.prototype.delete'] = {
  name: 'Request.prototype.delete',
  description: `
    DELETE request.
  `,
  returns: 'Promise',
  args: [
    fields.url,
    fields.requestOptions
  ]
};

fn['Request.prototype.patch'] = {
  name: 'Request.prototype.patch',
  description: `
    PATCH request.
  `,
  returns: 'Promise',
  args: [
    fields.url,
    fields.requestOptions
  ]
};

fn['Request.prototype.head'] = {
  name: 'Request.prototype.head',
  description: `
    HEAD request.
  `,
  returns: 'Promise',
  args: [
    fields.url,
    fields.requestOptions
  ]
};

fn['Request.prototype.transformBefore'] = {
  name: 'Request.prototype.transformBefore',
  description: `
    Transform the xhr and the options before the request.
  `,
  returns: '{ xhr, options }',
  args: [
    {
      ...fields.xhr,
      required: true
    },
    {
      ...fields.requestOptions,
      required: true
    }
  ]
};

fn['Request.prototype.transformAfter'] = {
  name: 'Request.prototype.transformAfter',
  description: `
    Transform the response result.
  `,
  returns: 'object',
  args: [
    {
      name: 'result',
      type: ['object'],
      description: `
        response result
      `,
      required: true
    }
  ]
};

fn['request.addInstance'] = {
  name: 'request.addInstance',
  description: `
    Add a new instance to request for managing.
  `,
  args: [
    {
      name: 'name',
      type: ['string'],
      description: `
        name of the instance
      `,
      required: true
    },
    {
      name: 'instance',
      type: ['Request'],
      description: `
        the instance
      `,
      required: true
    }
  ]
};

fn['request.removeInstance'] = {
  name: 'request.removeInstance',
  description: `
    Remove the request instance.
  `,
  args: [
    {
      name: 'name',
      type: ['string'],
      description: `
        name of the instance
      `,
      required: true
    }
  ]
};

fn['utils.class'] = {
  name: 'utils.class',
  description: `
    Create a string of the html classes from the object
  `,
  returns: 'string',
  args: [
    {
      name: 'object',
      type: ['Object'],
      description: `
        object with the classes and states
      `,
      required: true
    }
  ]
};

fn['utils.style'] = {
  name: 'utils.style',
  description: `
    Create a string of html styles from the object
  `,
  returns: 'string',
  args: [
    {
      name: 'object',
      type: ['Object'],
      description: `
        object with the styles and values
      `,
      required: true
    }
  ]
};

fn['utils.filter'] = {
  name: 'utils.filter',
  description: `
    Filter the array
  `,
  returns: 'Array',
  args: [
    {
      name: 'array',
      type: ['Array'],
      description: `
        array to filter
      `,
      required: true
    },
    {
      name: 'handler',
      type: ['string', 'RegExp', 'function'],
      description: `
        condition for filtering
      `,
      required: true
    },
    {
      name: 'keys',
      type: ['string[]', 'string'],
      description: `
        keys of the items
      `
    }
  ]
};

fn['utils.sort'] = {
  name: 'utils.sort',
  description: `
    Sort the array
  `,
  returns: 'Array',
  args: [
    {
      name: 'array',
      type: ['Array'],
      description: `
        array to filter
      `,
      required: true
    },
    {
      name: 'keys',
      type: ['boolean', 'Array[]', 'string[]', 'string'],
      defaultValue: 'true',
      description: `
        sorting conditions
      `
    },
    {
      name: 'direction',
      type: ['boolean', 'boolean[]'],
      defaultValue: '[]',
      description: `
        sorting direction
      `
    }
  ]
};

fn['utils.split'] = {
  name: 'utils.split',
  description: `
    split the string extended
  `,
  returns: 'string[]',
  args: [
    {
      name: 'str',
      type: ['string'],
      description: `
        value to split
      `,
      required: true
    },
    {
      name: 'del',
      type: ['string|RegExp'],
      description: `
        delimiter
      `
    },
    {
      name: 'exclude',
      type: ['string[]'],
      description: `
        list of the excluded boundaries
      `
    }
  ]
};

fn['utils.includeKeys'] = {
  name: 'utils.includeKeys',
  description: `
    Return a new object with the specified keys
  `,
  returns: 'object',
  args: [
    {
      name: 'obj',
      type: ['object'],
      description: `
        actual object
      `,
      required: true
    },
    {
      name: 'keys',
      type: ['string[]'],
      description: `
        necessary keys
      `,
      required: true
    },
  ]
};

fn['utils.excludeKeys'] = {
  ...fn['utils.includeKeys'], 
  name: 'utils.excludeKeys',
  description: `
    Return a new object without the specified keys
  `
}

fn['utils.isPlainObject'] = {
  name: 'utils.isPlainObject',
  description: `
    Check the object is plain (has constructor Object or Array)
  `,
  returns: 'boolean',
  args: [
    {
      name: 'value',
      type: ['*'],
      description: `
        value to check
      `,
      required: true
    }
  ]
};

fn['utils.copy'] = {
  name: 'utils.copy',
  description: `
    Copy the value
  `,
  returns: 'boolean',
  args: [
    {
      name: 'value',
      type: ['*'],
      description: `
        value to copy
      `,
      required: true
    },
    {
      name: 'options',
      type: ['object'],
      defaultValue: '{ nested: true, enumerable: true }',
      description: `options`,
    }
  ]
};

fn['utils.compare'] = {
  name: 'utils.compare',
  description: `
    Compare two values. 
    Objects is compared for identity of all properties.
  `,
  returns: 'boolean',
  args: [
    {
      name: 'a',
      type: ['*'],
      description: `
        first value
      `,
      required: true
    },
    {
      name: 'b',
      type: ['*'],
      description: `
        second value
      `,
      required: true
    },
    {
      name: 'options',
      type: ['object'],
      defaultValue: '{ enumerable: true }',
      description: `options`,
    }
  ]
};

fn['utils.encodeHtmlEntities'] = {
  name: 'utils.encodeHtmlEntities',
  description: `
    Convert the string to HTML entities
  `,
  returns: 'string',
  args: [
    {
      name: 'string',
      type: ['string'],
      description: `
        value to convert
      `,
      required: true
    }
  ]
};

fn['utils.decodeHtmlEntities'] = {
  name: 'utils.decodeHtmlEntities',
  description: `
    Decode the HTML entities
  `,
  returns: 'string',
  args: [
    {
      name: 'string',
      type: ['string'],
      description: `
        value to convert
      `,
      required: true
    }
  ]
};

fn['utils.toCamelCase'] = {
  name: 'utils.toCamelCase',
  description: `
    Convert the string to a camel case
  `,
  returns: 'string',
  args: [
    {
      name: 'string',
      type: ['string'],
      description: `
        value to convert
      `,
      required: true
    }
  ]
};

fn['utils.toDashCase'] = {
  name: 'utils.toDashCase',
  description: `
    Convert the string to a dash case
  `,
  returns: 'string',
  args: [
    {
      name: 'string',
      type: ['string'],
      description: `
        value to convert
      `,
      required: true
    }
  ]
};

fn['utils.getPropertyByKeys'] = {
  name: 'utils.getPropertyByKeys',
  description: `
    Get the nested object property by array keys
  `,
  returns: '*',
  args: [
    {
      name: 'keys',
      type: ['string[]'],
      description: `
        object keys
      `,
      required: true
    },
    {
      name: 'object',
      type: ['Object'],
      description: `
        object to find
      `,
      required: true
    }
  ]
};

fn['utils.hasPropertyByKeys'] = {
  name: 'utils.hasPropertyByKeys',
  description: `
    Check the object nested property existence
  `,
  returns: 'boolean',
  args: [
    {
      name: 'keys',
      type: ['string[]'],
      description: `
        object keys
      `,
      required: true
    },
    {
      name: 'object',
      type: ['Object'],
      description: `
        object to check
      `,
      required: true
    }
  ]
};

fn['utils.setPropertyByKeys'] = {
  name: 'utils.setPropertyByKeys',
  description: `
    Set the object nested property value
  `,
  returns: '*',
  args: [
    {
      name: 'keys',
      type: ['string[]'],
      description: `
        object keys
      `,
      required: true
    },
    {
      name: 'object',
      type: ['Object'],
      description: `
        object to set
      `,
      required: true
    },
    {
      name: 'fn',
      type: ['function'],
      description: `
        function to set the value
      `,
      required: true
    }
  ]
};

fn['utils.deletePropertyByKeys'] = {
  name: 'utils.deletePropertyByKeys',
  description: `
    Delete the property from the object
  `,
  returns: 'boolean',
  args: [
    {
      name: 'keys',
      type: ['string[]'],
      description: `
        object keys
      `,
      required: true
    },
    {
      name: 'object',
      type: ['Object'],
      description: `
        object to delete
      `,
      required: true
    },
    {
      name: 'fn',
      type: ['function'],
      description: `
        function to handle
      `
    }
  ]
};

fn['utils.createRandomString'] = {
  name: 'utils.createRandomString',
  description: `
    Generate a random string
  `,
  returns: 'string',
  args: [
    {
      name: 'length',
      type: ['integer'],
      defaultValue: '16',
      description: `
        length of the string
      `
    },
    {
      name: 'fn',
      type: ['function'],
      description: `
        Function to check the uniqueness. 
        If you return true the string will be regenerated until the function returns a negative value.
      `
    }
  ]
};


export default fn;