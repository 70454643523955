const fields = {};

fields.element = {
  name: 'element',
  type: ['HTMLElement']
};

fields.scope = {
  name: 'scope',
  type: ['Proxy']
};

fields.state = {
  name: 'state',
  type: ['string', 'Object'],
  description: 'router state object or name',
  required: true
};

fields.stateParams = {
  name: 'params',
  type: ['Object'],
  description: `
    url params
  `
};

fields.stateQuery = {
  name: 'query',
  type: ['Object'],
  description: `
    url query
  `
};

fields.stateHash = {
  name: 'hash',
  type: ['string'],
  description: `
    url hash
  `
};

fields.stateOptions = {
  name: 'options',
  type: ['Object'],
  description: `
    state options
  `
};

fields.xhr = {
  name: 'xhr',
  type: ['XMLHttpRequest'],
  description: `
    request instance
  `
};

fields.requestOptions = {
  name: 'options',
  type: ['Object'],
  description: `
    request options
  `
};

fields.url = {
  name: 'url',
  type: ['string'],
  description: 'URL',
  required: true
};

fields.xhr = {
  name: 'xhr',
  type: ['XMLHttpRequest'],
  description: 'request instance',
  required: true
};

fields.selector = {
  name: 'selector',
  type: ['string', 'function'],
  description: `
    native DOM selector or a function.
    The function will take component as an argument and return a boolean.
  `
};

fields.levels = {
  name: 'levels',
  type: ['integer[]', 'integer'],
  description: `
    levels of the nesting to find
  `
};

export default fields;