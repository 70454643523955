import Akili from 'akili';
import router from 'akili/src/services/router';

export default class DocsMenu extends Akili.Component {
  static templateUrl = "/templates/docs-menu.html";
  static matches = '[menu]';
  static events = ['active'];

  static define() {
    Akili.component('docs-menu', DocsMenu);
  }

  constructor(...args) {
    super(...args);

    this.scope.menu = [];
    this.scope.setActivity = this.setActivity.bind(this);
  }

  compiled() {
    this.attr('menu', this.setMenu);
    this.attr('active', this.setActivity);
  }

  setMenu(menu) {
    this.scope.menu = menu;

    for(let i = 0, l = this.scope.menu.length; i < l; i++) {
      let menu = this.scope.menu[i];
      menu.index = i;
      menu.isLast = i === l - 1;
      menu.isFirst = i === 0;
    }
  }

  setActivity(val) {
    typeof val == 'string'? this.setActivityByName(val): this.setActivityByIndex(val);
  }

  setActivityByIndex(index) {
    for(let i = 0, l = this.scope.menu.length; i < l; i++) {
      let menu = this.scope.menu[i];

      if(i === index) {
        this.makeActive(menu);
        break;
      }
    }
  }

  setActivityByName(name) {
    for(let i = 0, l = this.scope.menu.length; i < l; i++) {
      let menu = this.scope.menu[i];

      if(menu.name === name) {
        this.makeActive(menu);
        break;
      }
    }
  }

  makeActive(branch) {
    if(branch.isActive) {
      return;
    }

    for(let i = 0, l = this.scope.menu.length; i < l; i++) {
      let menu = this.scope.menu[i];
      menu.isActive = menu === branch;
    }

    this.attrs.onActive.trigger(branch);
  }
}
