import Akili from 'akili';
import router from 'akili/src/services/router';

export default class DocsAnchor extends Akili.Component {
  static define() {
    Akili.component('docs-anchor', DocsAnchor);
    Akili.alias('.docs-content h4,.docs-content h5', 'docs-anchor');
  }

  constructor(...args) {
    super(...args);

    if(this.el.querySelector('a')) {
      return this.cancel();
    }

    this.el.classList.add('docs-anchor');   
  }

  created() {
    if(!this.el.id) {
      this.el.setAttribute('id', this.formatId(this.el.innerHTML))
    }

    this.el.addEventListener('click', () => {
      router.reload({}, {}, this.el.id, { reload: false });
    });

    this.onStateChange = (e) => this.checkActivity(e.detail.path.hash);
    window.addEventListener('state-changed', this.onStateChange);
    this.checkActivity(this.transition.hash);
  }

  removed() {
    window.removeEventListener('state-changed', this.onStateChange);
  }

  formatId(str) {
    str = str.toLowerCase();
    str = str.replace(/\s+/g, '_');
    return 'docs_' + str;
  }

  checkActivity(hash) {
    if(hash === this.el.id) {
      this.el.classList.add('text-primary');
    }
    else {
      this.el.classList.remove('text-primary');
    }
  }
}
