import '../sass/main.scss';
import Akili from 'akili';
import App from './app';
import Home from './home';
import Docs from './docs';
import DocsMenu from './docs-menu';
import DocsAnchor from './docs-anchor';
import DocsFn from './docs-fn';
import Code from './code';
import Tabs from 'akili-tabs';
import router from 'akili/src/services/router';

App.define();
Docs.define();
DocsFn.define();
DocsMenu.define();
DocsAnchor.define();
Home.define();
Code.define();
Tabs.define();

document.addEventListener('DOMContentLoaded', () => {
  router.init('/home', false);
  Akili.init().catch((err) => console.error(err));
});

