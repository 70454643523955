import Akili from 'akili';
import router from 'akili/src/services/router';
import menu from './docs-menu-data';
import fn from './docs-fn-data';

export default class Docs extends Akili.Component {
  static define() {
    Akili.component('docs', Docs);

    router.add('docs', '/docs/:menu', {
      title:'Akili - documentation',
      templateUrl: '/templates/docs.html'
    });
  }

  constructor(...args) {
    super(...args);
    this.scope.currentMenu = {};
    this.scope.activeMenu = 0;
    this.scope.menu = menu;
    this.scope.fn = fn;
    this.scope.setMenuItem = this.setMenuItem.bind(this);
    this.scope.scrollToAnchor = this.scrollToAnchor.bind(this);
  }

  created() {    
    this.scope.activeMenu = this.transition.params.menu || 0;

    this.menuControlKeyupFn = (e) => {
      if(e.code == "ArrowRight" && this.scope.currentMenu.index < this.scope.menu.length - 1) {
        this.scope.activeMenu += 1;
      }
      else if(e.code == "ArrowLeft" && this.scope.currentMenu.index > 0) {
        this.scope.activeMenu -= 1;
      }
    };

    document.body.addEventListener('keyup', this.menuControlKeyupFn);
  }

  removed() {
    document.body.removeEventListener('keyup', this.menuControlKeyupFn);
  }

  setMenuItem(item) {  
    this.scope.currentMenu = item;
    this.scope.activeMenu = item.index;  
    
    if(this.__isResolved) {
      router.state('docs', { menu: item.name }, {}, '', { reload: false, saveScrollPosition: false }); 
    }            
  }

  scrollToAnchor() {
    const hash = this.transition.hash;

    if(!hash) {
      return;
    }

    const el = document.getElementById(hash);

    if(!el) {
      router.state('docs', { menu: this.scope.currentMenu.name }, {}, '', { reload: false });
      return;
    }

    el.scrollIntoView();
  }
}

