import Akili from 'akili';

export default class DocsFn extends Akili.Component {
  static templateUrl = "/templates/docs-fn.html";
  static templateCache = 5000;
  static matches = '[fn]';

  static define() {
    Akili.component('docs-fn', DocsFn);
  }

  constructor(...args) {
    super(...args);
    
    this.scope.fn = {};
    this.scope.getTitle = this.getTitle.bind(this);
    this.scope.getArgType = this.getArgType.bind(this);
    this.scope.getArgName = this.getArgName.bind(this);
  }
  
  compiled() {
    this.attr('fn', 'fn');
  }
  
  getTitle() {
    let names = (this.scope.fn.args || []).map((arg) => arg.name);
    let name = this.scope.fn.name + '(';
    name += names.join(', ');
    name += ')';
    return name;
  }
  
  getArgType(arg) {
    let type = Array.isArray(arg.type)? arg.type: [arg.type];    
    return type.join(' | ');
  }

  getArgName(arg) {
    let name = arg.name;

    if(arg.defaultValue) {
      name += "=" + arg.defaultValue;
    }

    if(!arg.required) {
      name = `[${name}]`;
    }

    return name;
  }
}