import Akili from 'akili';
import router from 'akili/src/services/router';

export default class Home extends Akili.Component {
  static define() {
    Akili.component('home', Home);

    router.add('home', '/home', {
      title: 'Akili - javascript framework',
      templateUrl: '/templates/home.html'
    });
  }
  
  created() {
    this.scope.examples = [
      [
        {
          title: 'hello.html',
          url: '/examples/hello.html',
          language: 'html',
          height: 120,
          jsfiddle: 'https://jsfiddle.net/ortexx/aqbmw3ey/'
        },
        {
          title: 'hello.js',
          url: '/examples/hello.js',
          language: 'javascript'
        }
      ],
      [
        {
          title: 'tree.html',
          url: '/examples/tree.html',
          language: 'html',
          jsfiddle: 'https://jsfiddle.net/ortexx/6fej1q6g/'
        },
        {
          title: 'tree.js',
          url: '/examples/tree.js',
          language: 'javascript'
        }
      ],
      [
        {
          title: 'todo.html',
          url: '/examples/todo.html',
          language: 'html',
          jsfiddle: 'https://jsfiddle.net/ortexx/37o1x3me/'
        },
        {
          title: 'todo.js',
          url: '/examples/todo.js',
          language: 'javascript'
        }
      ],
      [
        {
          title: 'maths.html',
          url: '/examples/maths.html',
          language: 'html',
          jsfiddle: 'https://jsfiddle.net/ortexx/qyhct688/'
        },
        {
          title: 'maths.js',
          url: '/examples/maths.js',
          language: 'javascript'
        }
      ],
      [
        {
          title: 'services.html',
          url: '/examples/services.html',
          language: 'html',
          jsfiddle: 'https://jsfiddle.net/ortexx/rkuc9deL/'
        },
        {
          title: 'services.js',
          url: '/examples/services.js',
          language: 'javascript'
        }
      ]
    ];
  }  
}

