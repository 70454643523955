const menu = [
  {
    title: 'Getting started',
    name: 'getting-started'
  },
  {
    title: 'Compilation',
    name: 'compilation'
  },
  {
    title: 'Relations',
    name: 'relations'
  },
  {
    title: 'Scope',
    name: 'scope'
  },
  {
    title: 'Lifecycle',
    name: 'lifecycle'
  },
  {
    title: 'Attributes',
    name: 'attributes'
  },
  {
    title: 'Events',
    name: 'events'
  },
  {
    title: 'Store',
    name: 'store'
  },
  {
    title: 'Routing',
    name: 'routing'
  },
  {
    title: 'Ajax requests',
    name: 'requests'
  },
  {
    title: 'Default components',
    name: 'components'
  },
  {
    title: 'Utils',
    name: 'utils'
  },
  {
    title: 'Tests',
    name: 'tests'
  },  
  {
    title: 'Server-side rendering',
    name: 'server'
  },
  {
    title: 'Architecture',
    name: 'architecture'
  },
  {
    title: 'Advanced features',
    name: 'advanced'
  },
  {
    title: 'Best practice',
    name: 'best'
  },
  {
    title: 'Popular questions',
    name: 'faq'
  }
];

export default menu;